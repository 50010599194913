<template>
  <div style="padding: 20px;">
     <a-tabs default-active-key="1" >
      <a-tab-pane key="1" tab="User Details">
        <div class="row g-3">
          <div class="col-12">
            <button v-on:click="updateUser()" class="btn btn-sm btn-success float-right"><em class="icon ni ni-save mr-1"></em> Save</button>
          </div>
        </div>
        <div class="row g-3">
            <div class="col-5">
              <div class="form-group">
                <label class="form-label float-right">First Name</label>
              </div>
            </div>
            <div class="col-7">
              <div class="form-group">
                <div class="form-control-wrap">
                  <a-input class="invisibox" v-model="user.firstName" :class="{'is-error' : $v.user.firstName.$invalid }" placeholder="John"/>
                </div>
              </div>
            </div>
        </div>
        <div class="row g-3">
            <div class="col-5">
              <div class="form-group">
                <label class="form-label float-right">Last Name</label>
              </div>
            </div>
            <div class="col-7">
              <div class="form-group">
                <div class="form-control-wrap">
                  <a-input class="invisibox" v-model="user.lastName" :class="{'is-error' : $v.user.lastName.$invalid }" placeholder="Smith"/>
                </div>
              </div>
            </div>
        </div>
        <div class="row g-3">
            <div class="col-5">
              <div class="form-group">
                <label class="form-label float-right">Email</label>
              </div>
            </div>
            <div class="col-7">
              <div class="form-group">
                <div class="form-control-wrap">
                  <a-input class="invisibox" v-model="user.email" :class="{'is-error' : $v.user.email.$invalid }" placeholder="John"/>
                </div>
              </div>
            </div>
        </div>
        <div class="row g-3">
          <div class="col-12">
            <button v-on:click="updateUser()" class="btn btn-sm btn-success float-right"><em class="icon ni ni-save mr-1"></em> Save</button>
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" tab="Permissions" force-render>
        <h6>User Roles</h6>
        <table class="table table-striped">
          <tr v-for="r in roles" v-bind:key="r.id">
            <td style="width: 100%;"><strong>{{r.name}}</strong></td>
            <td class="float-right">
                <a-switch @change="enableDisableRole(r)" v-model="r.enabled" />
            </td>
          </tr>
        </table>
      </a-tab-pane>
      <a-tab-pane key="3" tab="Settings">
         <table class="table table-striped">
          <tr>
            <td>Send User Password Reset</td>
            <td>
                <a-button v-on:click="sendPasswordReset()" icon="mail">
                    Send
                </a-button>
            </td>
          </tr> 
          <tr>
            <td>Allow Login</td>
            <td>
                <a-switch @change="enableDisableLogin()" v-model="user.allowLogin" />
            </td>
          </tr>
        </table>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
// import store from '@/store '
import { required } from 'vuelidate/lib/validators'

export default {
  validations: {
    user: {
      firstName: {
        required
      },
      lastName: {
        required
      }, 
      email: {
        required
      }
    }
  },
  props: {
    user: Object,
  },
  watch: {
    user: function(newVal) { // watch for change
      if (newVal != null && newVal.id != null) {
        this.getRoles()
      }
    },
  },
  data: function () {
    return {
      displayMe: true,
      roles: [],
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    }
  },
  name: 'editUser',
  mounted() {
    this.getRoles()
  },
  beforeDestroy() {
  },
  methods: {
    sendPasswordReset () {
       this.$http.get('/users/requestPasswordReset/' + this.user.email)
        .then(() => {
           this.$message.success('Password Reset sent')
        })
        .catch(() => {
          this.$message.error('There has been an error')
        })
    },
    getRoles () {
      this.$http.post('/lists/Get_AvailableUserRoles/', this.user)
        .then((response) => {
          this.roles = response.data
        })
        .catch(() => {
          this.$message.error('There has been an error')
        })
    },
    enableDisableLogin () {
      this.$http.post('/users/EnableDisable_Login/', this.user)
        .then(() => {
        })
        .catch(() => {
          this.$message.error('There has been an error')
        })
    },
    enableDisableRole (role) {
      role.userId = this.user.id
      this.$http.post('/users/EnableDisable_Role/', role)
        .then(() => {
          this.$message.success('User roles updated')
        })
        .catch(() => {
          this.$message.error('There has been an error')
        })
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.updateUser()
        } else {
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    updateUser: function () {
      if (this.$v.user.$invalid) {
        this.$message.error('Please check for any form errors')
      } else {
      this.$http.post('/users/Update_User/', this.user)
        .then(() => {
          this.$emit('userUpdated')
          this.$message.success('User Updated')
        })
        .catch(() => {
          this.$message.error('There has been an error')
        })
      }
    },
  },
}
</script>
<style scoped>
.form-label {
    padding-top: 6px
}
</style>