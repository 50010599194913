<template>
<div class="nk-content-body">
                                <div class="nk-block-head nk-block-head-sm pb-2">
                                    <div class="nk-block-between">
                                        <div class="nk-block-head-content">
                                            <h3 class="nk-block-title page-title">Users</h3>
                                            <div class="nk-block-des text-soft">
                                                <p>You have total {{users.length}} users.</p>
                                            </div>
                                        </div><!-- .nk-block-head-content -->
                                        <div class="nk-block-head-content">
                                            <div class="toggle-wrap nk-block-tools-toggle">
                                                <a href="#" class="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em class="icon ni ni-menu-alt-r"></em></a>
                                                <div class="toggle-expand-content" data-content="pageMenu">
                                                    <ul class="nk-block-tools g-3">
                                                        <!-- <li><a href="#" class="btn btn-white btn-outline-light"><em class="icon ni ni-download-cloud"></em><span>Export</span></a></li> -->
                                                        <li>
                                                          <button class="btn btn-dark text-light" v-on:click="openAddUser()"><em class="icon ni ni-plus mr-1"></em>Add User</button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div><!-- .toggle-wrap -->
                                        </div><!-- .nk-block-head-content -->
                                    </div><!-- .nk-block-between -->
                                </div><!-- .nk-block-head -->
                                <div class="nk-block">
                                    <div class="card p-2 card-stretch">
                                        <div class="card-inner-group">
                                            <div class="card-inner p-0">
                                                <div class="nk-tb-list nk-tb-ulist is-compact">
                                                     <vue-good-table v-if="users"
          :columns="columns"
          :rows="users"
          styleClass="vgt-table condensed"
          :search-options="{ enabled: true, trigger: 'enter', placeholder: 'Search My Users'}"
                              :sort-options="{ enabled: true,}"
                              :pagination-options="{
                                enabled: true,
                                mode: 'records',
                                perPage: 50,
                                position: 'bottom',
                                perPageDropdown: [50,100,150],
                                dropdownAllowAll: false,
                                setCurrentPage: 1,
                                nextLabel: 'Next',
                                prevLabel: 'Prev',
                                rowsPerPageLabel: 'Rows per page',
                                ofLabel: 'of',
                                pageLabel: 'page', // for 'pages' mode
                                allLabel: 'All',
                              }"
        >
         <template slot="table-row" slot-scope="props" >
          <div  v-if="props.column.field == 'roles'" >
            <span v-for="r in props.row.roles" v-bind:key="r.id" class="badge mr-2 badge-primary">{{r.name}}</span>
          </div>
          <div v-else-if="props.column.field === 'actions'">
            <div>
              <a-dropdown>
                   <div class="dropdown">
                    <a class="text-soft dropdown-toggle btn btn-icon btn-trigger" style="padding-top:0px;padding-bottom:0px;" >
                      <em class="icon ni ni-more-h"></em></a>
                  </div>
                  <a-menu slot="overlay">
                    <a-menu-item>
                      <a v-on:click="editUser(props.row)" href="javascript:;"><i class="bg-success-dim ni ni-edit mr-1"></i> Edit User</a>
                    </a-menu-item>
                    <a-menu-item>
                      <a-popconfirm
                        title="Are you sure delete the selected users?"
                        @confirm="deleteUser(props.row)"
                        @cancel="cancelDeleteUser"
                        okText="Yes"
                        cancelText="No"
                      >
                        <i class="ni ni-delete mr-1 bg-danger-dim"></i> Delete User
                      </a-popconfirm>
                    </a-menu-item>
                  </a-menu>
              </a-dropdown>
            </div>
          </div>
          <div v-else>
            {{props.formattedRow[props.column.field]}}
          </div>
         </template>
        </vue-good-table>
                                                </div><!-- .nk-tb-list -->
                                            </div><!-- .card-inner -->
                                        </div><!-- .card-inner-group -->
                                    </div><!-- .card -->
                                </div><!-- .nk-block -->

                                <!-- drawers -->
      <!-- add user  -->
  <a-modal
      title="Add User"
      height="60%"
      width="60%"
      :closable="true"
      :dialog-style="{ top: '35px'}"
      v-model="showAddUser"
    >
      <addUserWizard v-on:userAdded="userAdded"></addUserWizard>
    </a-modal>
      <!-- end add user  -->

                  <!-- edit user  -->
  <a-drawer
      title="Edit User"
      :width="600"
      placement="right"
      :closable="true"
      @close="closeEditUser()"
      :visible="showEditUser"
    >
      <editUser v-if="selectedUser" v-on:userEdited="userEdited" :user="selectedUser"></editUser>
    </a-drawer>
      <!-- end add user  -->
      <!-- import users  -->
    <a-drawer
      title="Import Users"
      placement="right"
      :closable="true"
      :visible="showImportUsers"
    >
      <p>ImportUsers</p>
      <p>Some contents...</p>
      <p>Some contents...</p>
    </a-drawer>
    <!-- end import users  -->
  <!-- end drawers -->
                            </div>
      
</template>
<script>
import addUserWizard from '@/components/users/addUserWizard.vue'
import editUser from '@/components/users/editUser/index'

export default {
  components: {
    addUserWizard,
    editUser,
  },
  data: function () {
    return {
      showAddUser: false,
      showImportUsers: false,
      selectedUser: null,
      selectedUsers: [],
      showEditUser: false,
      users: [],
      columns: [
        { label: 'User', field: 'userName' },
        { label: 'Email', field: 'email' },
        { label: 'Roles', field: 'roles', tdClass: 'text-center', thClass: 'text-center' },
        { label: 'Actions', field: 'actions', tdClass: 'text-center', thClass: 'text-center', width: 200 },
      ],
    }
  },
  methods: {

    editUser(user) {
      this.selectedUser = user
      this.showEditUser = true
    },
    cancelDeleteUser () {
      this.$message.error('Delete Cancelled')
    },
    deleteUser (user) {
      this.$http.post('/Users/Delete_User/', user)
        .then(() => {
          this.removeDeletedUser(user)
          this.$message.success('User Successfully Removed')
        })
        .catch(() => {
          this.$message.error('There has been an error')
        })
    },
    removeDeletedUser (user) {
      this.users.splice(this.users.findIndex(usr => usr.id === user.id), 1)
    },
    toggleUserSelected (selectedRows) {
      this.selectedUsers = selectedRows.selectedRows
    },
    getUsers () {
      this.$http.get('/users/get_Users')
        .then((response) => {
          this.users = response.data
        })
        .catch(() => {
          this.$message.error('There has been an error')
        })
    },
    userUpdated () {
      this.closeEditUser()
      this.getUsers()
      this.selectedUser = null
    },
    userEdited() {
      this.closeEditUser()
      this.getUsers()
      this.selectedUser = null
    },
    userAdded() {
      this.getUsers()
      this.closeAddUser()
    },
    openImportUsers () {
      this.showImportUsers = true
      this.closeaddUser()
    },
    closeImportUsers () {
      this.showImportUsers = false
    },
    openAddUser () {
      this.showAddUser = true
      this.closeImportUsers()
    },

    closeAddUser () {
      this.showAddUser = false
    },
    closeEditUser () {
      this.showEditUser = false
      this.getUsers()
      this.selectedUser = {}
    },
    getView (){ this.$http.get('/ViewAccess/users')
        .then(() => { 
        })
        .catch(() => { 
        })},
  },
  created () {
      this.getView()
      this.getUsers()
  },
}
</script>
<style>
</style>
